.takeoutTime {
    font-weight: bold;
}
.row td{
    padding: 5px;
}   
.distance {
    color: #999;
}
.row td:nth-child(1){
}
.row {
    border: 1px solid #ccc;
}