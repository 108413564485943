// @import 'react-big-calendar/lib/sass/styles';
// @import 'react-big-calendar/lib/addons/dragAndDrop/styles';
#root > div {
    // margin-top: 60px;
}
@media screen and (min-width: 751px) {
    body {
        width: 375px;
        margin: auto;
        background-color: #ddd;
    }
    #root {
        zoom: 1;
        background-color: #fff;
        min-height: 100vh;
    }
}