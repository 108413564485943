.table {
    padding: 20px;
    margin-bottom: 20px;
}
.table th, .table td {
    width: 30%;
    padding: 10px 20px;
}
.step {
    border: 1px solid #333;
    border-radius: 30px;
    height: 45px;
    line-height: 45px;
    font-weight: bold;
}
.step4 {
    width: 80%;
    margin: auto;
}
.button {
    width: 80%;
    margin: 30px auto;
}