.header_after {
    float: right;
}
.root {
    margin-bottom: 10px;
}
.accordion {
}
.accordionClosed{
    display: none;
}