.detailItem {
    list-style: none;
    height: 40px;
}
.detailItem:after {
    content: "〉";
    float: right;
}
.mainItem_after {
    float: right;
}