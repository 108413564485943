.subLink {
    text-align: right;
    text-decoration: underline;
    margin-bottom: 10px;
}
.centerNotice {
    text-align: center;
    text-decoration: underline;
    margin: 20px 0;
}
.buttons {
    text-align: center;
}
.buttons button {
    width: 42%;
    border: 1px solid #333;
    border-radius: 30px;
}
.buttons button:nth-child(1) {
    margin-right: 5px;
}
.address {
    margin: 20px 0;
}
